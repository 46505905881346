<template>
  <div class="container">
    <el-tabs
      v-model="activeName"
      class="box1"
      type="card"
    >
      <!-- 第一步 -->
      <el-tab-pane
        label="第一步:基本信息"
        name="one"
      >
        <div class="formbox">
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="nextStep1"
          >
            保存并下一步
          </el-button>
          <el-button
            class="btn_s"
            style="margin-right: 20px"
            @click="cancelBtn"
          >
            取 消
          </el-button>
          <el-form
            :inline="true"
            label-position="right"
            label-width="150px"
            :model="infosForm"
            class="demo-form-inline formDisplay"
          >
            <div style="width: 100%; font-size: 14px; margin: 10px 30px">
              基本信息
            </div>
            <el-form-item
              label="根据会员等级匹配:"
              class="queryItem1"
            >
              <el-radio-group v-model="infosForm.isCheckMemberlevel">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              v-if="infosForm.isCheckMemberlevel==1"
              label="会员等级:"
              class="queryItem1"
            >
              <el-select
                v-model="infosForm.confMemberlevelIds"
                multiple
              >
                <el-option
                  v-for="(itme, index) in GradeList"
                  :key="index"
                  :label="itme.levelName"
                  :value="Number(itme.levelId)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="客户报价名称:"
              class="queryItem1"
            >
              <el-input
                v-model="infosForm.offerName"
                placeholder="请输入"
                clearable
                class="input"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="公斤段风格:" class="queryItem1">
              <el-radio-group v-model="weightDirection">
                <el-radio label="0">横向</el-radio>
                <el-radio label="1">纵向</el-radio>
              </el-radio-group>
            </el-form-item> -->

            <el-form-item
              label="运输方式:"
              class="queryItem1"
            >
              <el-select
                v-model="infosForm.channelTransportId"
                placeholder="请选择"
                class="input"
                filterable
                clearable
                :disabled="isEdit || canChangeqd"
                @change="changeTransPort"
              >
                <el-option
                  v-for="item in channelTransportList"
                  :key="item.channelTransportId"
                  :label="item.channelTransportName"
                  :value="item.channelTransportId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="所属渠道:"
              class="queryItem1"
            >
              <el-select
                v-model="channelIds"
                placeholder="请选择"
                class="input"
                filterable
                clearable
                :disabled="isEdit || canChangeqd"
                @change="changeChanne"
              >
                <el-option
                  v-for="item in channelList"
                  :key="item.channelId"
                  :label="item.channelName"
                  :value="item.channelId"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="生效开始时间:"
              class="queryItem1"
            >
              <el-date-picker
                clearable
                class="input"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="infosForm.effectiveStartTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="生效结束时间:"
              class="queryItem1"
            >
              <el-date-picker
                clearable
                class="input"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="infosForm.effectiveEndTime"
                type="datetime"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>


            <el-form-item
              label="发出仓库:"
              class="queryItem1 multipleclass"
            >
              <el-select
                v-model="startStorages"
                placeholder="请选择"
                class="input multipleclass"
                multiple
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="到达仓库:"
              class="queryItem1 multipleclass"
            >
              <el-select
                v-model="endStorages"
                placeholder="请选择"
                class="input multipleclass"
                multiple
              >
                <el-option
                  v-for="item in cangkuList"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>



            <el-form-item
              label="备注:"
              class="queryItem_textarea"
            >
              <el-input
                type="textarea"
                rows="3"
                v-model="infosForm.comment"
                placeholder="请输入"
                clearable
                class="input"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <!-- 第二步 -->
      <el-tab-pane
        label="第二步:导入渠道报价"
        :disabled="isEdit || hasId"
        name="two"
      >
        <div class="formbox">
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="nextStep2()"
          >
            下一步
          </el-button>
          <div style="font-size: 14px; font-weight: 700; margin-left: 120px">
            当前报价名称: {{ infosForm.offerName }}
          </div>
          <div class="weigthStyl">
            <div style="font-size: 14px; margin-right: 20px">公斤段风格:</div>
            <el-radio-group v-model="weightDirection">
              <el-radio label="0">横向</el-radio>
              <el-radio label="1">纵向</el-radio>
            </el-radio-group>
          </div>
          <h5 style="text-align: center; color: #666">
            提示:
            导入报价将删除原有报价信息,从Excel中直接拷贝,两个数字自己采用Tab键进行分割,两行之间采用分行进行分割
          </h5>

          <!-- <el-form-item label="公斤段风格:" class="queryItem1">
            <el-radio-group v-model="weightDirection">
              <el-radio label="0">横向</el-radio>
              <el-radio label="1">纵向</el-radio>
            </el-radio-group>
          </el-form-item> -->

          <div class="mainBox1">
            <div
              class="X_Label"
              v-if="weightDirection == '1'"
            >
              <div
                class="xbox"
                ref="xlabel"
              >
                <div style="width: 60px; min-width: 60px">{{ weiLabel }}</div>
                <div
                  class="label_items"
                  v-for="(item, index) in fenquArr"
                  :key="index"
                >
                  {{ item.partitionName }}
                </div>
              </div>
            </div>
            <!--  -->
            <div
              class="yBox"
              ref="ybox"
            >
              <div
                class="Y_Label"
                v-if="weightDirection == '0'"
              >
                <div style="margin-top: 4px">{{ weiLabel }}:</div>
                <div
                  class="label_items"
                  v-for="(item, index) in fenquArr"
                  :key="index"
                >
                  {{ item.partitionName }}:
                </div>
              </div>
              <div class="rightArea">
                <el-input
                  class="textarea_"
                  :rows="rowsNum"
                  type="textarea"
                  placeholder="粘贴内容"
                  v-model="textareas"
                >
                </el-input>
              </div>
            </div>
            <h5
              v-if="fenquArr.length == 0"
              style="color: #f00; margin: 20px 0 20px 120px"
            >
              提示:当前渠道暂无分区! 请先新建分区
            </h5>
            <h5
              v-if="!this.infosForm.channelId"
              style="color: #f00; margin: 20px 0 20px 120px"
            >
              提示:您还没选择渠道! 请先选择渠道
            </h5>
          </div>
        </div>
      </el-tab-pane>

      <!-- 第三步 -->
      <el-tab-pane
        label="第三步:编辑报价"
        :disabled="hasId"
        name="three"
      >
        <div class="formbox formBox3">
          <el-button
            class="btn_e6a23c"
            style="margin-bottom: 20px"
            @click="nextStep3()"
          >
            保 存
          </el-button>
          <el-button
            class="btn_s"
            style="margin-right: 20px"
            @click="cancelBtn3"
          >
            取 消
          </el-button>
          <h5
            v-if="myArr.length == 0"
            style="color: #f00; margin: 20px 0"
          >
            提示:请先在第二步导入数据哦~
          </h5>
          <div style="font-size: 14px; text-align: center; font-weight: 700">
            当前报价名称: {{ infosForm.offerName }}
          </div>

          <div class="saveBaoJiaBox">
            <div class="save_BJ_item LeftLabel">
              <div class="bj_item">重量段</div>
              <div class="bj_item">最低重量</div>
              <div class="bj_item">最高重量</div>
              <div class="bj_item">计费方式</div>
              <div class="bj_item">单位重量</div>
              <div
                class="bj_item"
                v-for="(qitem, qindex) in fenquArr3"
                :key="qindex"
              >
                {{ qitem.offerPartitionName || "" }}
              </div>
            </div>
            <div
              class="save_BJ_item"
              v-for="(item, index) in myArr"
              :key="index"
            >
              <div class="bj_item">
                <el-input
                  placeholder="请输入"
                  v-model="item.weightSegmentName"
                ></el-input>
              </div>
              <div class="bj_item">
                <el-input
                  placeholder="请输入"
                  v-model="item.startWeight"
                  type="number"
                ></el-input>
              </div>
              <div class="bj_item">
                <el-input
                  placeholder="请输入"
                  v-model="item.endWeight"
                  type="number"
                ></el-input>
              </div>

              <div class="bj_item">
                <el-select
                  channelOfferFormulaId
                  v-model="item.channelOfferFormulaId"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="items1 in OfferFormulaList"
                    :key="items1.formulaId"
                    :label="items1.formulaName"
                    :value="items1.formulaId"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="bj_item">
                <el-input
                  placeholder="请输入"
                  v-model="item.unitWeight"
                  type="number"
                ></el-input>
              </div>
              <div
                class="bj_item"
                v-for="(subItem, subIndex) in item.partitionAmountList"
                :key="subIndex"
              >
                <el-input
                  placeholder="请输入"
                  type="number"
                  v-model="subItem.offerAmount"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import paging from '@/components/pagings.vue'
export default {
  inject: ['reload'],
  components: { paging },
  data() {
    return {
      activeName: 'one',
      weightDirection: '0', //重量段方向  0==横向，1==纵向
      infosForm: {
        offerName: '',
        channelTransportId: '', //运输方式id
        channelId: '', //渠道id
        effectiveStartTime: '',
        effectiveEndTime: '',
        offerId: '',
        channelOfferId: '',
        comment: '',
        isCheckMemberlevel: 0,
        confMemberlevelIds: [],
        startStorages: [],
        endStorages: [],
      },
      channelIds: '', //临时的渠道id
      channelArr: [], //运输方式和渠道的原列表
      channelList: [], //渠道列表
      channelTransportList: [], //运输方式列表

      textareas: '',
      rowsNum: 10, //几行
      myArr: [
        // {
        //   weightSegmentName: '', //重量段名称
        //   startWeight: '',
        //   endWeight: '',
        //   channelOfferFormulaId: "", //计费方式
        //   partitionAmountList: [],
        // }
      ], //处理后的数据
      fenquArr: [], //分区
      fenquArr3: [], //已有报价分区
      weiLabel: '重量段', //
      OfferFormulaList: [],
      value1: '',
      pageType: '', //页面类型
      activeFlag: false, //只请求一次
      isEdit: false, // 编辑变新增
      hasId: true, // 是否有id
      canChangeqd: false, // 是否k可选渠道
      editFenquList: [], //编辑,原来分区数组
      //客户等级下拉
      GradeList: [],
      cangkuList: [], //仓库数组
      startStorages: [],
      endStorages: [],
    }
  },
  created() {
    this.myGetStorageList()
    this.getMemberLeveDownList()
    if (this.$route.query.data) {
      let editData = JSON.parse(this.$route.query.data) || []
      this.pageType = this.$route.query.pageType || ''
      if (editData) {
        this.infosForm = editData
        this.infosForm.channelOfferId = editData.offerId

        this.channelIds = editData.channelId
        this.getChannelOfferStorageInfoV4(editData.offerId)
        if (this.pageType == 'edit') {
          this.isEdit = true
          this.getChannelOfferDetail()
        } else {
          this.isEdit = false
          this.hasId = true
        }
      }
    }
    if (!this.infosForm.channelOfferId) {
      this.hasId = true
    } else {
      this.hasId = false
    }
    this.getAllChannel()
    this.getOfferFormulaList()
  },
  activated() {
    // console.log("activated");
    // console.log(this.$route.query.pageType);
    if (this.$route.query.data) {
      let editData = JSON.parse(this.$route.query.data) || []
      this.pageType = this.$route.query.pageType || ''
      if (editData) {
        this.activeName = 'one'
        this.infosForm = editData
        this.infosForm.channelOfferId = editData.offerId
        this.channelIds = editData.channelId
      }
    }

    if (this.$route.query.pageType == 'add') {
      this.infosForm = {
        offerName: '',
        channelTransportId: '', //运输方式id
        channelId: '', //渠道id
        effectiveStartTime: '',
        effectiveEndTime: '',
        offerId: '',
        channelOfferId: '',
        isCheckMemberlevel: 0,
        confMemberlevelIds: [],
      }
      this.channelIds = ''
      this.activeName = 'one'
      this.myArr = []
      this.isEdit = false
    } else if (this.$route.query.pageType == 'edit') {
      console.log('激活后编辑进来')
      this.isEdit = true
      this.getChannelOfferDetail()
    }
    this.getAllChannel()
    if (!this.infosForm.channelOfferId) {
      this.hasId = true
    } else {
      this.hasId = false
    }
  },
  watch: {
    textareas: function (newVal, old) {
      this.getImportData(newVal)
    },
    weightDirection: function (newVal, old) {
      let that = this
      this.getImportData(this.textareas)
      // if (newVal == "1") {
      //   //纵向

      // }
    },
    activeName: function (newVal, old) {
      let that = this
      if (newVal == 'two') {
        that.getChannelOfferPartitionList()
      }
      if (newVal == 'three') {
        this.getChannelOfferPartitionList()
        // this.getOfferFormulaList();
      }
    },
  },
  mounted() {},
  methods: {
    getChannelOfferStorageInfoV4(offerId) {
      Api.getChannelOfferStorageInfoV4({ offerId }).then((res) => {
        const result = res.data.result || []
        this.startStorages =
          result.startStorages &&
          result.startStorages.map((n) => {
            n.storageId
          })
        this.endStorages =
          result.endStorages &&
          result.endStorages.map((n) => {
            n.storageId
          })
      })
    },
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || []
      })
    },
    //获取会员等级下拉列表
    getMemberLeveDownList() {
      Api.getMemberLeveDownList().then((res) => {
        this.GradeList = res.data.result || []
      })
    },
    changeChanne(e) {},
    getImportData(newVal) {
      var String = []
      String.push(newVal)

      let datsArr = String[0].split('\n') || []
      if (datsArr.length <= 0) {
        return
      }

      let flag = /\s/ //所有空白字符
      let newArr = [] //切割后的数组
      for (var i = 0; i < datsArr.length; i++) {
        if (datsArr[i]) {
          let strArr = datsArr[i].split(flag)
          let arr1 = []
          for (var z = 0; z < strArr.length; z++) {
            if (strArr[z]) {
              arr1.push(strArr[z])
            }
          }
          newArr.push(arr1)
        }
      }
      if (newArr.length == 0) {
        return
      }

      //  重量段  横向
      if (this.weightDirection == '0') {
        let weightArr_x = newArr[0]
        console.log(weightArr_x)
        //处理重量段
        let myArr = this.chuLiZhongLiang(weightArr_x)

        let AmountArr = newArr.splice(1, newArr.length - 1)
        for (var d = 0; d < myArr.length; d++) {
          for (var t = 0; t < myArr[d].partitionAmountList.length; t++) {
            let obj = myArr[d].partitionAmountList[t]
            if (AmountArr[t] && AmountArr[t][d]) {
              obj.offerAmount = AmountArr[t][d] || ''
            } else {
              obj.offerAmount = ''
            }
            myArr[d].partitionAmountList[t] = obj
          }
        }

        this.myArr = JSON.parse(JSON.stringify(myArr))
        console.log('横向')
        console.log(this.myArr)
      } else {
        // 纵向
        let weightArr_y = []
        for (var i = 0; i < newArr.length; i++) {
          weightArr_y.push(newArr[i][0])
        }
        let myArr = this.chuLiZhongLiang(weightArr_y)
        // let AmountArr = newArr.splice(1, newArr.length - 1);
        for (var d = 0; d < myArr.length; d++) {
          let arr1 = myArr[d].partitionAmountList
          for (var t = 0; t < arr1.length; t++) {
            let obj = arr1[t]
            obj.offerAmount = newArr[d][t + 1] || ''
            arr1[t] = obj
          }
          myArr[d].partitionAmountList = arr1
        }

        this.myArr = JSON.parse(JSON.stringify(myArr))
        // console.log("纵向");
        // console.log(this.myArr);
      }
    },
    chuLiZhongLiang(weightArr) {
      let myArr = []
      //处理重量段
      for (var a = 0; a < weightArr.length; a++) {
        let flag1 = /[^0-9.]+/
        let wlists = weightArr[a].split(flag1) || []
        let wlist = []
        for (var j = 0; j < wlists.length; j++) {
          if (wlists[j]) {
            wlist.push(wlists[j])
          }
        }

        let startWeight = 0
        let endWeight = 0
        if (wlist.length > 1) {
          startWeight = wlist[0]
          endWeight = wlist[1]
        } else if (wlist.length == 1) {
          startWeight = wlist[0]
          endWeight = wlist[0]
        }

        //组装数据
        var objs = {
          weightSegmentName: weightArr[a], //重量段名称
          startWeight: startWeight,
          endWeight: endWeight,
          channelOfferFormulaId: '', //计费方式
          unitWeight: '', //单位重量
          partitionAmountList: [],
        }
        myArr.push(objs)
      }
      //关联分区id
      for (var c = 0; c < myArr.length; c++) {
        let arr = []
        for (var g = 0; g < this.fenquArr.length; g++) {
          let id = this.fenquArr[g].channelOfferPartitionId
          let obj = {
            offerAmount: '',
            offerPartitionId: id,
          }
          arr.push(obj)
        }
        myArr[c].partitionAmountList = arr
      }

      return myArr
    },

    // 获取报价计算公式
    getOfferFormulaList() {
      let param = {
        formulaName: '',
        pageStart: 1,
        pageTotal: 999,
      }
      Api.getOfferFormulaList(param).then((res) => {
        if (res.data.status == 'success') {
          this.OfferFormulaList = res.data.result.data || []
        }
      })
    },
    //保存第一步
    addOrUpdateChannelOffer() {
      let that = this
      this.infosForm.channelId = this.channelIds
      // let param = JSON.parse(JSON.stringify(this.infosForm));
      console.log('this.infosForm222')
      console.log(this.infosForm)
            let startStorages = this.cangkuList
        .filter((n) => {
          if (this.startStorages.includes(n.id)) {
            return n
          }
        })
        .map((n, i) => {
          return {
            sort: i,
            storageId: n.id,
            storageName: n.storageName,
          }
        })
      let endStorages = this.cangkuList
        .filter((n) => {
          if (this.endStorages.includes(n.id)) {
            return n
          }
        })
        .map((n, i) => {
          return {
            sort: i,
            storageId: n.id,
            storageName: n.storageName,
          }
        })


      let param = {
        channelId: this.channelIds,
        effectiveStartTime: this.infosForm.effectiveStartTime || '',
        effectiveEndTime: this.infosForm.effectiveEndTime || '',
        offerName: this.infosForm.offerName || '',
        offerId: this.infosForm.channelOfferId || '',
        comment: this.infosForm.comment || '',
        isCheckMemberlevel: this.infosForm.isCheckMemberlevel || 0,
        confMemberlevelIds: this.infosForm.confMemberlevelIds || [],
        startStorages,
        endStorages,
      }
      

      let sign = tools.getSign(param)
      param.sign = sign
      Api.addOrUpdateOfferV4(param).then((res) => {
        this.$message.success(res.data.$message || '操作成功')
        if (this.pageType !== 'edit') {
          this.infosForm.channelOfferId = res.data.result.channelOfferId
          if (this.infosForm.channelOfferId) {
            this.hasId = false
          }
        }
        this.canChangeqd = true

        setTimeout(() => {
          if (that.isEdit) {
            this.activeName = 'three'
          } else {
            this.activeName = 'two'
          }
        }, 1000)
      })
    },
    //第一步保存
    nextStep1() {
      console.log('this.infosForm')
      console.log(this.infosForm)
      this.addOrUpdateChannelOffer()
    },
    //第2步保存
    nextStep2() {
      this.activeName = 'three'
    },
    nextStep3() {
      let that = this
      let newsArrs = this.myArr
      for (var a1 = 0; a1 < newsArrs.length; a1++) {
        let items = newsArrs[a1].partitionAmountList

        for (var a2 = 0; a2 < items.length; a2++) {
          if (!items[a2].offerPartitionId) {
            newsArrs[a1].partitionAmountList.splice(a2, 1)
            a2--
          }
        }
      }
      this.myArr = newsArrs
      if (this.pageType == 'edit' && this.isEdit) {
        let paramEdit = {
          channelId: this.infosForm.channelId,
          channelOfferId: this.infosForm.channelOfferId,
          weightList: this.myArr,
        }
        let sign = tools.getSign(paramEdit)
        paramEdit.sign = sign
        // 编辑
        Api.updateOfferWeightAndMoney(paramEdit).then((res) => {
          this.$message.success(res.data.message || '新增成功')
          setTimeout(() => {
            tools.closePage()
            that.reload()
          }, 100)
        })
      } else {
        let param = {
          channelId: this.infosForm.channelId,
          channelOfferId: this.infosForm.channelOfferId,
          weightList: this.myArr,
        }
        let sign = tools.getSign(param)
        param.sign = sign
        // 新增
        Api.addOfferWeightAndMoney(param).then((res) => {
          this.$message.success(res.data.message || '新增成功')
          setTimeout(() => {
            tools.closePage()
            that.reload()
          }, 1000)
        })
      }
      // console.log(this.myArr);
    },
    //第一步取消
    cancelBtn() {
      this.weightDirection = '0'
      this.infosForm = {
        offerName: '',
        channelTransportId: '', //运输方式id
        channelId: '', //渠道id
        effectiveStartTime: '',
        effectiveEndTime: '',
        offerId: '',
        comment: '',
        isCheckMemberlevel: 0,
        confMemberlevelIds: [],
      }
      this.channelIds = ''
    },
    //第3步取消
    cancelBtn3() {
      this.$confirm('此操作将不做更改并关闭页面, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          tools.closePage()
          this.reload()
        })
        .catch(() => {})
    },
    getChannelOfferDetail() {
      Api.getChannelOfferDetail({
        channelOfferId: this.infosForm.channelOfferId,
      }).then((res) => {
        let datas = res.data.result || []
        this.infosForm = datas

        let newsArrs = datas.weightList || []
        for (var a1 = 0; a1 < newsArrs.length; a1++) {
          let items = newsArrs[a1].partitionAmountList

          for (var a2 = 0; a2 < items.length; a2++) {
            if (!items[a2].offerPartitionId) {
              newsArrs[a1].partitionAmountList.splice(a2, 1)
              a2--
            }
          }
        }
        this.myArr = newsArrs
        // this.myArr = datas.weightList || [];
        if (this.myArr.length > 0) {
          let dataArr = this.myArr

          //横向
          // if (this.weightDirection == "0") {
          //   let weightStr = ""; //重量段
          //   let priceStr = ""; //报价
          //   let AllStr = "";
          //   for (var j = 0; j < dataArr.length; j++) {
          //     weightStr += dataArr[j].weightSegmentName + "  ";
          //   }
          //   let arrLength = 0;
          //   if (dataArr[0].partitionAmountList.length > 0) {
          //     arrLength = dataArr[0].partitionAmountList.length;
          //   }

          //   for (var b = 0; b < arrLength; b++) {
          //     let strItem = "";
          //     for (var j = 0; j < dataArr.length; j++) {
          //       let arr2 = dataArr[j].partitionAmountList || [];
          //       strItem += arr2[b].offerAmount + "  ";
          //     }
          //     priceStr += strItem + "\n";
          //   }

          //   AllStr = weightStr + "\n" + priceStr;
          //   this.textareas = AllStr;
          // }

          let partitionList = dataArr[0].partitionAmountList || []
          // this.fenquArr3 = partitionList;
          let arrids = []
          for (var i = 0; i < partitionList.length; i++) {
            arrids.push(partitionList[i].offerPartitionId)
          }
          this.editFenquList = arrids
        } else {
          this.isEdit = false
        }
      })
    },
    // 获取渠道
    getAllChannel() {
      Api.getChannelList({
        channelName: '',
        pageStart: 1,
        pageTotal: 999,
      }).then((res) => {
        if (res.data.status == 'success') {
          let datas = res.data.result.data || []
          this.channelArr = datas
          let ysfsIdArr = [] //运输方式id
          let ysfsArr = [] //运输方式
          for (var i = 0; i < datas.length; i++) {
            let tid = datas[i].channelTransportId
            if (ysfsIdArr.indexOf(tid) == -1) {
              ysfsIdArr.push(tid)
              ysfsArr.push(datas[i])
            }
          }
          this.channelTransportList = ysfsArr //去重后的运输方式列表
          if (this.pageType == 'edit') {
            for (var j = 0; j < datas.length; j++) {
              if (datas[j].channelId == this.infosForm.channelId) {
                this.infosForm.channelTransportId = datas[j].channelTransportId
                this.changeTransPort(datas[j].channelTransportId)
              }
            }
          }
        }
      })
    },

    // 选择运输方式
    changeTransPort(e) {
      let id = e //运输方式id
      let channelList = [] //渠道空数组

      let cids = []
      let datas = this.channelArr //原数组
      for (var b = 0; b < datas.length; b++) {
        if (id == datas[b].channelTransportId) {
          if (cids.indexOf(datas[b].channelId) == -1) {
            cids.push(datas[b].channelId)
            channelList.push(datas[b])
          }
        }
      }
      this.channelList = channelList
      if (channelList.length > 0) {
        // this.channelIds = channelList[0].channelId;
      }
    },
    // 获取分区列表
    getChannelOfferPartitionList() {
      let param = {
        channelId: this.infosForm.channelId, //渠道id
        partitionName: '', //分区名称
        pageStart: 1,
        pageTotal: 999,
      }
      Api.getChannelOfferPartitionList(param).then((res) => {
        if (res.data.status == 'success') {
          let fenquArrList = res.data.result.data || []
          this.fenquArr = fenquArrList
          // this.fenquArr = fenquArrList.reverse();
          let length = this.fenquArr.length || 0
          if (length < 10) {
            this.rowsNum = 10
          } else {
            this.rowsNum = length + 1 //+1是自定义的,留个空隙
          }
          if (this.pageType == 'edit' && this.activeName == 'three') {
            if (this.activeFlag) {
              return
            }
            //只执行一次
            this.activeFlag = true

            let arr1 = this.fenquArr
            let arr2 = this.editFenquList
            console.log(arr2)
            console.log('哦豁有bug')
            let newArr = []
            for (var i = 0; i < arr1.length; i++) {
              if (arr2.indexOf(arr1[i].channelOfferPartitionId) == -1) {
                let obj = {
                  channelListId: '',
                  channelOfferWeightId: '',
                  formulaId: '',
                  formulaName: '',
                  offerAmount: '',
                  offerPartitionId: arr1[i].channelOfferPartitionId,
                  offerPartitionName: arr1[i].partitionName,
                }
                newArr.push(obj)
              }
            }
            if (this.myArr.length < 1) {
              return
            }
            let marr1 = JSON.parse(JSON.stringify(this.myArr))
            // let newarr2 = [];

            // for (var aa = 0; aa < newArr.length; aa++) {
            //   let obj = {
            //     offerAmount: "",
            //     offerPartitionId: newArr[aa].channelOfferPartitionId,
            //     offerPartitionName: newArr[aa].partitionName,
            //   };
            //   newarr2.push(obj);
            // }

            for (var b = 0; b < marr1.length; b++) {
              for (var cc = 0; cc < newArr.length; cc++) {
                marr1[b].partitionAmountList.push(newArr[cc])
              }
            }

            for (var d = 0; d < marr1.length; d++) {
              let arr_2 = marr1[d].partitionAmountList || []
              if (arr_2.length > 1) {
                for (var e = 1; e < arr_2.length; e++) {
                  if (!arr_2[e].channelListId) {
                    arr_2[e].channelListId = ''
                    arr_2[e].channelOfferWeightId =
                      arr_2[0].channelOfferWeightId
                    arr_2[e].formulaId = arr_2[0].formulaId
                    arr_2[e].formulaName = arr_2[0].formulaName
                  }
                }
                marr1[d].partitionAmountList = arr_2
              }
            }

            this.myArr = JSON.parse(JSON.stringify(marr1))
            // this.myArr = marr1; //赋值二级字段,这样写  v-mode会失效

            this.fenquArr3 = this.myArr[0].partitionAmountList || []
          } else {
            this.fenquArr3 = this.fenquArr
            for (var c = 0; c < this.fenquArr3.length; c++) {
              this.fenquArr3[c].offerPartitionName =
                this.fenquArr3[c].partitionName
            }
          }
          // this.rowsNum = res.data.result.data.length || 10;
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.weigthStyl {
  display: flex;
  margin-left: 120px;
  align-items: center;
  margin-top: 20px;
}
.diamain {
  .queryBox {
    display: flex;
    align-items: center;

    .query_left {
      border: 1px solid #d7d7d7;
      line-height: 28px;
    }
  }
  ::v-deep {
    .el-form-item {
      margin-bottom: 10px;
    }
  }

  .formDia {
    .queryItem3 {
      width: 100%;
      min-height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #d7d7d7;
      margin-bottom: 0;
      ::v-deep {
        .el-form-item__label,
        .el-form-item__content,
        .el-input__inner {
          line-height: 28px !important;
          border: none;
          width: 100%;
        }
      }
    }
    .queryItem2 {
      width: 100%;
      // margin-right: 2%;
      min-height: 40px;
      display: flex;
      align-items: center;
      border: 1px solid #d7d7d7;
      ::v-deep {
        .el-form-item__label,
        .el-form-item__content,
        .el-input__inner {
          line-height: 28px !important;
          border: none;
          width: 100%;
        }
      }
      .tagClass {
        width: 100%;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        background-color: #f5f7fa;
        // cursor:not-allowed;
        min-height: 40px;
        .tagList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
        }
      }

      .inputframe {
        height: 30px !important;
        line-height: 30px;
        ::v-deep {
          .el-input__inner {
            height: 28px !important;
          }
        }
      }
      .input {
        border: none;
        width: 100%;
      }

      .areaCount {
        cursor: pointer;
      }
    }
    .inputQuery {
      width: 100%;
      // margin-bottom: 10px;
      ::v-deep {
        .el-input-group__append {
          padding: 0 10px;
        }
        .el-input-group__append {
          width: 70px;
        }
      }
      .btn_s {
        background-color: #fff;
        color: #333;
        border: 1px solid #606266;
        padding: 8px 10px;
        border-radius: 4px;
        margin: 0 auto;
        cursor: pointer;
        width: 68px;
      }
      .btn_s:hover {
        color: #606266 !important;
        border: 1px solid #ef7c1b;
      }
    }
    .areaCountTable {
      margin-top: 20px;
    }
  }
}
.btn_e6a23c {
  background-color: #e6a23c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}
.btn_s {
  background-color: #fff;
  color: #333;
  border: 1px solid #606266;
  padding: 8px 10px;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}
.btn_s:hover {
  color: #606266 !important;
  border: 1px solid #ef7c1b;
}
.formBtn {
  cursor: pointer;
}
.categoryTitle {
  font-size: 14px;
  font-weight: bold;
}
.formbox {
  width: 90%;
  min-width: 600px;
  margin: 20px auto;
  //   padding: 10px 30px;
  box-sizing: border-box;
  //   display: flex;
  // 第三步的
  .saveBaoJiaBox {
    display: flex;
    // overflow-x: auto;
    margin-top: 20px;
    font-size: 14px;

    .save_BJ_item {
      width: 120px;
      margin-right: 30px;

      .bj_item {
        height: 40px;
        line-height: 40px;
        margin: 10px 0;
        min-width: 100px;
      }
    }
    .LeftLabel {
      text-align: right;
    }
  }

  .formDisplay {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: center;
    ::v-deep .el-form-item {
      margin-bottom: 0;
    }

    .beizhuBox {
      width: 100%;
      margin-top: 30px;
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 150px);
        }
      }
      // .inputText {
      //     width: calc(100% - 150px);
      // }
    }
    .queryItem1 {
      min-width: 360px;
      width: 40%;
      margin: 8px 30px 0 0;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      height: 32px;
      //   justify-content: space-between;
      .queryLabel {
        width: 70px;
        min-width: 70px;
        color: #606266;
        text-align: right;
        margin-right: 10px;
      }
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 96px);
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-input__inner {
          line-height: 28px;
          height: 28px;
          border: none;
        }
        .el-form-item__label {
          line-height: 30px;
        }
        .el-select {
          width: 100%;
        }
      }

      .input {
        width: 100%;
        height: 30px;
      }
    }
    .queryItem_textarea {
      min-width: 360px;
      width: 40%;
      margin: 8px 30px 0 0;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      // align-items: center;
      //   justify-content: space-between;
      .queryLabel {
        width: 70px;
        min-width: 70px;
        color: #606266;
        text-align: right;
        margin-right: 10px;
      }
      ::v-deep {
        .el-form-item__content {
          width: calc(100% - 96px);
          // line-height: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .el-textarea__inner {
          border: none;
        }
        // .el-form-item__label {
        //   line-height: 30px;
        // }
        .el-select {
          width: 100%;
        }
      }

      // .input {
      //   width: 100%;
      //   height: 30px;
      // }
    }
  }

  .form_fq {
    margin-top: 20px;
  }

  .inpu {
    width: 180px;
  }
  .inpu1 {
    width: 210px;
  }

  .mainBox1 {
    margin-top: 40px;
    min-height: 300px;
    padding: 4px;
    width: 100%;
    font-size: 14px;
    overflow-x: scroll;
    .label_items {
      margin-left: 20px;
      // margin-top: 4px;
      min-width: 80px;
      line-height: 1.5;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .X_Label {
      // position: absolute;
      // left: 0;
      // top: -30px;
      // margin-left: 120px;
      .xbox {
        display: flex;
        align-items: center;
      }
    }
    .yBox {
      display: flex;
      .Y_Label {
        // position: absolute;
        // left: -130px;
        // top: 0;
        width: 120px;
        text-align: right;
      }

      .rightArea {
        flex: 1;
        ::v-deep .el-textarea__inner {
          padding: 0 8px;
        }
        .textarea_ {
          border: 1px solid #d7d7d7;
        }
      }
    }
  }
}

.formbox ::v-deep {
  .el-button {
    padding: 7px 10px !important;
  }
}
.formBox3 {
  width: 100%;
  overflow: auto;
  max-height: 84vh;
}
.box {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  text-align: center;
}
.box1 {
  background-color: #fff;
}

.addBtn_ {
  border: 1px solid #606266;
  padding: 5px !important;
  background-color: #fafafa;
  color: #333333;
}
.addBtn_ :hover {
  color: #606266 !important;
  border: 1px solid #ef7c1b;
}
.multipleclass {
  height: auto !important;
}
</style>
